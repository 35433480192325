import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import LayoutExtra from "../components/layoutExtra"

const PrivacyPolicy = () => {

	const data = useStaticQuery(graphql`
    query {
			privacyPolicyIcon: file(relativePath: { eq: "icons/privacy-policy.svg" }) {
        publicURL
      },
    }
	`)

	return (
		<LayoutExtra>
			<SEO title="プライバシーポリシー" />
			<div className="page-head">
				<div className="h2-subtitle">
					<img src={data.privacyPolicyIcon.publicURL} alt="プライバシーポリシー" />
					<h2>プライバシーポリシー</h2>
					<p>Privacy Policy</p>
				</div>
			</div>

			<h3>個人情報の取り組み</h3>
			<p>
				近年のIT（情報技術）の進歩とインターネットの普及により、経済効率や社会の利便性などが向上する一方、
				全ての個人に対して情報漏洩や濫用のリスクが高まりつつあります。
				その中で、当社は個人情報保護についての社会的責務を強く認識し、重要な経営課題の一つとして真摯に取り組んでいます。<br />
				<br />
				そして、事業に携わるすべての個人情報を保護する為に、以下の通り「個人情報保護に関する基本方針」を定め、
				全力をあげて個人情報の適正な管理に努めます。
			</p>
			<p className="text-right mt-5">株式会社グローバルワイズ<br />代表取締役  松原真那武</p>
			<hr className="dots-hr" />

			<h3>個人情報保護に関する基本方針</h3>
			<p>
				当社の役員及び従業員等、当社の業務に従事する全ての者に、本方針を周知させると共に教育と啓発に努め、
				個人情報保護に関するコンプライアンス・プログラムを遵守し、個人情報を安全かつ正確に取り扱います。
			</p>
			<ol>
				<li>個人情報を取り扱う場合は、業務上の明確な目的のもとで、必要とされる範囲内に限定し、常に適正な手段により、収集、利用、提供します。 利用目的は、収集方法に応じて個人情報を入力するフォーム等に明示します。</li>
				<li>個人情報への不正アクセス、紛失、破壊、改ざん、漏洩等の事故を予防する為に合理的な安全対策を実施します。</li>
				<li>個人情報管理責任者を設置し、コンプライアンス・プログラムの実施と運用に係わる責任と権限を与え、 個人情報の適切な管理及び提供等の対応を行います。</li>
				<li>個人情報の取り扱いに際しては、国が定める個人情報に関する法令、社会的な規範などを遵守します。</li>
				<li>個人情報の適切な管理を維持する為に、監査、教育、見直しを行い、コンプライアンス・プログラムを継続的に改善します。</li>
			</ol>
			<hr className="dots-hr" />

			<h3>個人情報の取扱いについて</h3>
			<ol>
				<li>個人情報の利用目的
					<p>当社では以下のような目的で、個人情報を利用させて頂きます。</p>
					<ul>
						<li>お客様等へのご請求・お支払等の業務処理、契約関係処理および業務上のご連絡</li>
						<li>お客様等からのお問い合わせ・ご依頼への対応</li>
						<li>当社が取り扱う商品・サービスの案内</li>
						<li>セミナー・展示会・キャンペーン等販促活動に関する案内</li>
						<li>年賀状、事務所移転、異動等のご案内</li>
						<li>役員及び従業員の雇用、人事労務管理、経理、総務等の業務上必要な手続き</li>
						<li>採用応募者の選考</li>
						<li>その他、ご本人に事前にお知らせし、ご同意いただいた目的</li>
					</ul></li>

				<li>第3者への提供について
					<p>当社は以下のいずれかに該当する場合を除き、お客様等の個人情報を第三者へ開示または提供しません。</p>
					<ul>
						<li>ご本人の同意がある場合</li>
						<li>法令に基づき開示・提供を求められた場合</li>
						<li>統計的なデータなどご本人を特定できない状態で開示・提供する場合</li>
					</ul></li>

				<li>Cookieについて
					<p>クッキーはWebサイトからご利用者の端末に記録目的で送信される小さなテキストファイルです。<br />クッキーはご利用者の端末、
						通常Webブラウザを識別しますが、ご利用者を個人として識別することはありません。</p>

					<p>当社は、お客様へのサービス向上ならびに当社商品の広告配信および宣伝などの用途でクッキーを使用しております。<br />また、当社のサイトでは、利用者の当社サイトの
						訪問状況を把握するためにGoogle 社のサービスであるGoogle Analyticsを利用しています。<br />
						当社のサイトでGoogle Analyticsを利用すると、当社が発行するクッキーを利用して、Google 社が利用者の当社サイトの訪問履歴を収集、記録、分析します。<br />当社は、
						Google 社から分析結果を受け取り、利用者の当社サイトの訪問状況を把握しています。</p>
				</li>
				<li>個人情報の開示、訂正、利用停止等について
					<p>当社は、個人情報の照会、訂正、利用停止、消去等のご要望があったときは、所定の手続で利用者本人であることを確認の上、速やかに対応致しますので、個人情報の
						取扱いに関するお問い合わせ先までご連絡をお願いいたします。</p>
				</li>

				<li>個人情報取扱事業者について
					<p>
						株式会社グローバルワイズ<br />
						愛知県名古屋市中村区名駅南二丁目14番19号<br />
						代表取締役  松原真那武</p>
				</li>

				<li>個人情報の取り扱いに関するお問い合わせ先
					<p>
						株式会社グローバルワイズ  管理部  個人情報保護担当<br />
						TEL：052-581-2600 FAX：052-533-3611<br />
						受付時間 10:00-17:00</p>
				</li>
			</ol>
		</LayoutExtra>
	)
}

export default PrivacyPolicy